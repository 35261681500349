import React from 'react';
import './Introducing.css';
import threeD_app_screens from '../../assets/images/3d_app_screens.png';
import { t } from '../../language/i18n';

function Introducing() {
  return (
    <section id="introducing" className="introducing_placement">
      <div className="introducing_container">
        <h2>{t("Introducing")}</h2>
        <div className="description_placement">
          <div className='img_placement'>
            <img src={threeD_app_screens} alt={t("3D App Screens")} />
          </div>
          <p className="text_description">{t("BrainLog is a smart and straightforward mobile app that optimizes your note-taking and management. Whether you're jotting down ideas or organizing important information, BrainLog is designed to make your life easier and more productive.")}</p>
        </div>

      </div>
    </section>
  );
}

export default Introducing;
