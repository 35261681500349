import React from 'react';
import './HomePage.css';
import MainSection from './MainSection';
import Introducing from './Introducing';
import Features from './Features';
import Screens from './Screens';


function HomePage() {
  return (
    <div className='homepage'>
        <MainSection/>
        <Introducing/>
        <Screens/>
        <Features/>

    </div>
  );
}

export default HomePage;
