import React from 'react';
import './Footer.css';
import { APP_NAME } from '../config/config';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="footer">
      <div className='contentPlace'>
        <div className='contentContainer'>

          <p>&copy; 2024 {APP_NAME}. All rights reserved.</p>
          <Link to="/privacy_policy" className="footer-link">Privacy Policy</Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
