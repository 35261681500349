import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./ScreensSlider.css";

import image1 from '../../assets/images/screens/1 Main screen.png';
import image2 from '../../assets/images/screens/2 Edit screen.png';
import image3 from '../../assets/images/screens/3 Save screen.png';
import image4 from '../../assets/images/screens/4 Open screen.png';
import image5 from '../../assets/images/screens/5 Help mode.png';

import { t } from '../../language/i18n';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style,
             display: "flex",
             justifyContent: "flex-end",
            }}
        onClick={onClick}
      />
       
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
    <div
        className={className}
        style={{ ...style,
                 display: "flex",
                 justifyContent: "flex-start",
                }}
        onClick={onClick}
        >
    </div>

    );
  }

function ScreensSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
   // arrows: false, 
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
          breakpoint: 1030,
          settings: {
            slidesToShow: 2,
            //slidesToScroll: 1,
            //infinite: true,
            //dots: true
          }
        },
        {
          breakpoint: 750,
          settings: {
            slidesToShow: 1,
            //slidesToScroll: 1
          }
        }
      ]
  };


  return (

    <div className="slider_container">
    <Slider {...settings}>
        <div>
            <div className='img_placement'> 
                <img src={image1} alt={t("Main screen")} />
                <h3>{t("Main screen")}</h3>
            </div>
        </div>
        <div>
            <div className='img_placement'>
                <img src={image2} alt={t("Edit screen")} />
                <h3>{t("Edit screen")}</h3>
            </div>
        </div>
        <div>
            <div className='img_placement'>
                <img src={image3} alt={t("Save screen")} />
                <h3>{t("Save screen")}</h3>
            </div>
        </div>
        <div>  
            <div className='img_placement'> 
                <img src={image4} alt={t("Open screen")} />
                <h3>{t("Open screen")}</h3>
            </div>
        </div>
        <div>
            <div className='img_placement'>
                <img src={image5} alt={t("Help mode")} />
                <h3>{t("Help mode")}</h3>
            </div>
        </div>
    </Slider>
    </div>

  );
}

export default ScreensSlider;
