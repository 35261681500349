// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
//import en from './locales/en.json'; // Import your translation files
//import ru from './locales/ru.json';
import en from './en.json'; 
import ru from './ru.json'; 
console.log(1);
i18n
  .use(LanguageDetector) // Detect the user's language
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: en },
      ru: { translation: ru },
    },
    fallbackLng: 'en',
    debug: false, // Set to false in production
    interpolation: {
      escapeValue: false, // React already protects from XSS
    },
    //?lng=en
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
      caches: ['cookie'],
    },
  });

export const t = (key, options) => i18n.t(key, options);
export default i18n;
