import React from 'react';
import './Features.css';
import newFile from '../../assets/images/features/new_file.svg';
import googleDrive from '../../assets/images/features/google_drive.svg';
import interface_icon from '../../assets/images/features/interface.svg';
import search from '../../assets/images/features/search.svg';
import folder from '../../assets/images/features/folder.svg';
import help_circle_outline from '../../assets/images/features/help_circle_outline.svg';
import { t } from '../../language/i18n';

function Features() {
  return (
    <section id="features" className="features_placement">
      <div className="features_container">
        <h2>{t("App's Features")}</h2>
        <div className="feature_list_placement">
          <div className='feature_list'>

            <div className='feature_item_1'>
              <div className='feature_item_image'>
                <img src={newFile} alt={t("New File Icon")}/>
              </div>
              <div className='feature_item_description'>
                <h3>{t("Effortless Note Creation")}</h3>
                <p>{t("Creating new notes with BrainLog is a breeze, allowing you to capture your thoughts quickly and effortlessly. Be it jotting down a quick idea or drafting a detailed plan, you can always organize them later at your convenience.")}</p>
              </div>
            </div>

            <div className='feature_item_2'>
              <div className='feature_item_image'>
                <img src={googleDrive} alt={t("Google Drive Icon")}/>
              </div>
              <div className='feature_item_description'>
                <h3>{t("Seamless Cloud Integration")}</h3>
                <p>{t("BrainLog integrates seamlessly with Google Drive, ensuring your notes are securely stored in the cloud and accessible from any device.")}</p>
              </div>
            </div>



          </div>
          <div className='feature_list'>

            <div className='feature_item_1'>
                <div className='feature_item_image'>
                  <img src={search} alt={t("Search Icon")}/>
                </div>
                <div className='feature_item_description'>
                  <h3>{t("Powerful Search Functionality")}</h3>
                  <p>{t("Never lose a note again with BrainLog’s advanced search. Search by folder names, file titles, or even content within your files. The semantic search feature helps you find notes based on related keywords, ensuring quick access to your information.")}</p>
                </div>
            </div>

            <div className='feature_item_2'>
              <div className='feature_item_image'>
                <img src={folder} alt={t("Folder Icon")}/>
              </div>
              <div className='feature_item_description'>
                <h3>{t("Organized Note Management")}</h3>
                <p>{t("BrainLog’s familiar file organization system allows you to create folders and subfolders to keep your notes neatly arranged. Easily categorize and manage your notes to maintain a smooth and efficient workflow.")}</p>
              </div>
            </div>
            
          </div>

          <div className='feature_list'>

            <div className='feature_item_1'>
              <div className='feature_item_image'>
                <img src={interface_icon} alt={t("Pencil Ruler Icon")}/>
              </div>
              <div className='feature_item_description'>
                <h3>{t("User-Friendly Design")}</h3>
                <p>{t("BrainLog's intuitive interface makes note-taking simple. With all control buttons at the bottom, you can access features with a tap. Its clean design ensures you can start using it immediately, without complicated setup or tutorials.")}</p>
              </div>
            </div>

            <div className='feature_item_2'>
              <div className='feature_item_image'>
                <img src={help_circle_outline} alt={t("Help Icon")}/>
              </div>
              <div className='feature_item_description'>
                <h3>{t("Help Mode")}</h3>
                <p>{t("If you ever need assistance, BrainLog’s well-organized help options provide all the information you need to make the most out of the app. Utilize all its capabilities without any hassle.")}</p>
              </div>
            </div>
            
          </div>

        </div>

      </div>
    </section>
  );
}

export default Features;
