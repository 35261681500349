import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <section id="privacy-policy" className="privacy-policy">
          <div class="container">
            <h1>Privacy Policy</h1>
            <p><strong>Effective Date:</strong> June 15, 2024</p>

            <h2>1. Introduction</h2>
            <p>Your privacy is important to us. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you use our mobile application.</p>

            <h2>2. Information We Access</h2>
            <p>BrainLog operates entirely on your device and utilizes the Google Drive API for cloud storage. The app accesses, but does not collect or store, the following information:</p>
            <ul>
                <li><strong>Google Account Information:</strong> When you sign into BrainLog with your Google account, the app accesses your basic profile information, such as your email address and profile picture.</li>
                <li><strong>Google Drive Files:</strong> BrainLog can only access Google Drive files created within the app.</li>
                <li><strong>Device Files:</strong> With your consent, the app may access files in shared folders on your device.</li>
            </ul>

            <h2>3. How We Use Your Information</h2>
            <p>The accessed information is used solely to provide services like editing, deleting, adding, and searching text files stored in your Google Drive or on your device.</p>

            <h2>4. Data Sharing and Disclosure</h2>
            <p>BrainLog does not share, sell, or disclose your personal information to third parties. All data access and processing occur solely through the Google Drive API and within the app.</p>

            <h2>5. Data Security</h2>
            <p>We use industry-standard security measures, including encryption and secure access controls, to protect your data. However, no method of transmission over the internet or electronic storage is entirely secure.</p>

            <h2>6. Data Retention</h2>
            <p>We do not retain your personal information. If you delete the app or disconnect your Google account, your data will remain in your Google account and Google Drive in accordance with Google's data retention policies.</p>

            <h2>7. Your Rights</h2>
            <p>You have the following rights regarding your personal information:</p>
            <ul>
                <li><strong>Access and Update:</strong> You can access and update your personal information through your Google account settings.</li>
                <li><strong>Close Access:</strong> You can revoke BrainLog's access to your profile data and Google Drive files via your Google Account settings under "Security".</li>
            </ul>

            <h2>8. Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy periodically. When we do, we will post the updated version on this page.</p>

            <h2>9. Contact Us</h2>
            <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
            <ul>
                <li><strong>Email Address:</strong> support@brainlog.app</li>
            </ul>

        </div>

    </section>
  );
}

export default PrivacyPolicy;
