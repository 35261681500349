import React from 'react';
import './Screens.css';
import ScreensSlider from './ScreensSlider';
import { t } from '../../language/i18n';

function Screens() {
    return (
        <section id="screens" className="screens_placement">
            <div className="screens_container">
                <h2>{t("App Screens")}</h2>
                <ScreensSlider/>
            </div>
        </section>  
    );
}

export default Screens;

